import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ViewChild, ElementRef, HostListener } from '@angular/core';
import { formatDate } from '@angular/common';

import { Subscription } from 'rxjs';

import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';
import * as moment from 'moment';

import { SettingsService } from '@myapp/portal/services';

const STORAGE_KEY_DATE_RANGE_DISPLAY = 'totals.dateRangeDisplay';

interface DateRangePreset {
	label: string,
	interval: string,
	dateRange: DateRange,
	selected?: boolean
}

@Component({
	selector: 'app-daterange-picker',
	templateUrl: 'daterange-picker.component.html',
	styleUrls: ['daterange-picker.component.scss'],
	exportAs: 'appDateRangePicker'
})
export class DateRangePickerComponent implements OnInit, OnDestroy {
	private settings$: Subscription;

	@ViewChild('dropdown', { static: true })
	dropdown: BsDropdownDirective;

	@Input()
	loading: boolean = false;

	@Input()
	disabled: boolean = false;

	@Input()
	align: 'left' | 'right' = 'left';

	@Input()
	dateRange: DateRange = { start: null, end: null };

	@Output()
	dateRangeChange: EventEmitter<DateRange> = new EventEmitter<DateRange>();

	dateRangeDisplay: string = '';
	dateRangeTooltip: string = '';
	dateRangePresets: DateRangePreset[] = [];
	selectedDateRange: Date[] = [null, null];
	maxDate: Date = new Date();
	weekStartingDay: number = 0;

	get today(): Date {
		return new Date();
	}

	constructor(
		private settingsService: SettingsService,
		private element: ElementRef
	) {
	}

	private initialize() {
		let dateRange = [new Date(), new Date()];
		let storedDateRange = sessionStorage.getItem('totals.dateRange');

		if (storedDateRange) {
			dateRange = JSON.parse(storedDateRange)
				.map(date => new Date(date));
		} else {
			dateRange = [
				moment().startOf('week').toDate(),
				moment().endOf('week').toDate()
			];
		}

		this.dateRange = {
			start: dateRange[0],
			end: dateRange[1]
		};

		this.onSelectDateRange(dateRange);
	}

	private getDateOnly(date: Date | string) {
		return formatDate(date, 'yyyy-MM-dd', 'en-US');
	}

	private onSettingsLoaded() {
		this.initialize();

		this.dateRangePresets = [{
			label: 'Today',
			interval: 'day',
			dateRange: {
				start: new Date(),
				end: new Date()
			}
		}, {
			label: 'Yesterday',
			interval: 'day',
			dateRange: {
				start: moment(new Date()).add(-1, 'day').toDate(),
				end: moment(new Date()).add(-1, 'day').toDate()
			}
		}, {
			label: 'This Week',
			interval: 'week',
			dateRange: {
				start: moment().startOf('week').toDate(),
				end: moment().endOf('week').toDate()
			}
		}, {
			label: 'Last Week',
			interval: 'week',
			dateRange: {
				start: moment().add(-1, 'week').startOf('week').toDate(),
				end: moment().add(-1, 'week').endOf('week').toDate()
			}
		}, {
			label: 'This Month',
			interval: 'month',
			dateRange: {
				start: moment().startOf('month').toDate(),
				end: moment().endOf('month').toDate()
			}
		}, {
			label: 'Last Month',
			interval: 'month',
			dateRange: {
				start: moment().add(-1, 'month').startOf('month').toDate(),
				end: moment().add(-1, 'month').endOf('month').toDate()
			}
		}, {
			label: 'This Quarter',
			interval: 'quarter',
			dateRange: {
				start: moment().startOf('quarter').toDate(),
				end: moment().endOf('quarter').toDate()
			}
		}, {
			label: 'Last Quarter',
			interval: 'quarter',
			dateRange: {
				start: moment().add(-1, 'quarter').startOf('quarter').toDate(),
				end: moment().add(-1, 'quarter').endOf('quarter').toDate()
			}
		}, {
			label: 'This Year',
			interval: 'year',
			dateRange: {
				start: moment().startOf('year').toDate(),
				end: moment().endOf('year').toDate()
			}
		}, {
			label: 'Last Year',
			interval: 'year',
			dateRange: {
				start: moment().add(-1, 'year').startOf('year').toDate(),
				end: moment().add(-1, 'year').endOf('year').toDate()
			}
		}, {
			label: 'Custom',
			interval: null,
			dateRange: {
				start: null,
				end: null
			}
		}];

		this.onDateRangeChange([this.dateRange.start, this.dateRange.end]);
		this.setDateRangeDisplayValue();
		this.dateRangeChange.emit(this.dateRange);
	}

	private setDateRangeDisplayValue() {
		let dateRangeStartMonthYear = `${this.dateRange.start.getMonth()}-${this.dateRange.start.getFullYear()}`;
		let dateRangeEndMonthYear = `${this.dateRange.end.getMonth()}-${this.dateRange.end.getFullYear()}`;

		let dateRangePreset = this.dateRangePresets.find(m =>
			this.getDateOnly(m.dateRange.start) == this.getDateOnly(this.dateRange.start) &&
			this.getDateOnly(m.dateRange.end) == this.getDateOnly(this.dateRange.end)
		);

		if (dateRangeStartMonthYear === dateRangeEndMonthYear) {
			this.dateRangeTooltip = `${formatDate(this.dateRange.start, 'MMMM d', 'en-US')} - ${formatDate(this.dateRange.end, 'd', 'en-US')}`;
		} else {
			this.dateRangeTooltip = `${formatDate(this.dateRange.start, 'MMMM d', 'en-US')} - ${formatDate(this.dateRange.end, 'MMMM d', 'en-US')}`;
		}

		if (dateRangePreset && dateRangePreset.dateRange.start && dateRangePreset.dateRange.end) {
			this.dateRangeDisplay = dateRangePreset.label;
		} else if (dateRangeStartMonthYear === dateRangeEndMonthYear) {
			this.dateRangeDisplay = `${formatDate(this.dateRange.start, 'MMM d', 'en-US')} - ${formatDate(this.dateRange.end, 'd', 'en-US')}`;
		} else {
			this.dateRangeDisplay = `${formatDate(this.dateRange.start, 'MMM d', 'en-US')} - ${formatDate(this.dateRange.end, 'MMM d', 'en-US')}`;
		}

		localStorage.setItem(STORAGE_KEY_DATE_RANGE_DISPLAY, this.dateRangeDisplay);
	}

	ngOnInit() {
		this.dateRangeDisplay = localStorage.getItem(STORAGE_KEY_DATE_RANGE_DISPLAY) || 'This Week';

		this.settings$ = this.settingsService.userSettings.subscribe(settings => {
			if (settings) {
				let setting = settings.find(m => m.constantName == 'FirstDayOfWeek');
				let settingValue = JSON.parse(setting.settingDetails);

				this.weekStartingDay = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
					.indexOf(settingValue);

				let locale = moment.locale();
				moment.updateLocale(locale, {
					week: {
						dow: this.weekStartingDay
					},
				});
				moment.locale(locale);

				this.onSettingsLoaded();
			}
		});

		this.initialize();
	}

	ngOnDestroy() {
		if (this.settings$) {
			this.settings$.unsubscribe();
		}
	}

	onDateRangeChange(selection: any) {
		let dateRangePreset = this.dateRangePresets.find(m =>
			this.getDateOnly(m.dateRange.start) == this.getDateOnly(selection[0]) &&
			this.getDateOnly(m.dateRange.end) == this.getDateOnly(selection[1])
		);

		if (!dateRangePreset) {
			dateRangePreset = this.dateRangePresets.find(m =>
				m.dateRange.start == null &&
				m.dateRange.end == null
			);
		}

		this.onSelectDateRangePreset(dateRangePreset);
	}

	onSelectDateRange(selection: Date[]) {
		this.selectedDateRange = [
			moment(selection[0]).startOf('day').toDate(),
			moment(selection[1]).endOf('day').toDate()
		];
	}

	onSelectDateRangePreset(selection: DateRangePreset) {
		this.dateRangePresets.forEach(dateRangePreset => {
			dateRangePreset.selected = this.getDateOnly(dateRangePreset.dateRange.start) == this.getDateOnly(selection.dateRange.start) &&
				this.getDateOnly(dateRangePreset.dateRange.end) == this.getDateOnly(selection.dateRange.end);
		});

		if (selection.dateRange.start && selection.dateRange.end) {
			this.onSelectDateRange([selection.dateRange.start, selection.dateRange.end]);
		}
	}

	onSelectDateRangePresetByLabel(dateRangePresetLabel: string) {
		let dateRangePreset = this.dateRangePresets.find(m => m.label === dateRangePresetLabel);

		if (dateRangePreset) {
			this.onSelectDateRangePreset(dateRangePreset);
		}
	}

	onSelectPrevDateRange() {
		let dateRangePreset = this.dateRangePresets.find(m => m.selected);

		if (dateRangePreset && dateRangePreset.interval) {
			let startDate = moment(this.selectedDateRange[0])
				.add(-1, dateRangePreset.interval as moment.unitOfTime.DurationConstructor)
				.startOf(dateRangePreset.interval as moment.unitOfTime.StartOf)
				.toDate();

			let endDate = moment(this.selectedDateRange[0])
				.add(-1, dateRangePreset.interval as moment.unitOfTime.DurationConstructor)
				.endOf(dateRangePreset.interval as moment.unitOfTime.StartOf)
				.toDate();

			this.onSelectDateRange([startDate, endDate]);
		} else {
			let timeDifferenceInCurrentPreset = this.selectedDateRange[1].getTime() - this.selectedDateRange[0].getTime();
			let daysDifferenceInCurrentPreset = Math.round(timeDifferenceInCurrentPreset / (24 * 60 * 60 * 1000));

			let endDate = moment(this.selectedDateRange[0]).add(-1, 'day').toDate();
			let startDate = moment(endDate).add(-daysDifferenceInCurrentPreset, 'day').toDate();

			this.onSelectDateRange([startDate, endDate]);
		}

		this.onApply();
	}

	onSelectNextDateRange() {
		let dateRangePreset = this.dateRangePresets.find(m => m.selected);

		if (dateRangePreset && dateRangePreset.interval) {
			let startDate = moment(this.selectedDateRange[0])
				.add(1, dateRangePreset.interval as moment.unitOfTime.DurationConstructor)
				.startOf(dateRangePreset.interval as moment.unitOfTime.StartOf)
				.toDate();

			let endDate = moment(this.selectedDateRange[0])
				.add(1, dateRangePreset.interval as moment.unitOfTime.DurationConstructor)
				.endOf(dateRangePreset.interval as moment.unitOfTime.StartOf)
				.toDate();

			if (startDate < new Date()) {
				this.onSelectDateRange([startDate, endDate]);
			}
		} else {
			let timeDifferenceInCurrentPreset = this.selectedDateRange[1].getTime() - this.selectedDateRange[0].getTime();
			let daysDifferenceInCurrentPreset = Math.round(timeDifferenceInCurrentPreset / (24 * 60 * 60 * 1000));

			let endDate = moment(this.selectedDateRange[0]).add(1, 'day').toDate();
			let startDate = moment(endDate).add(daysDifferenceInCurrentPreset, 'day').toDate();

			if (startDate < new Date()) {
				this.onSelectDateRange([startDate, endDate]);
			}
		}

		this.onApply();
	}

	onApply() {
		if (this.dateRange.start !== this.selectedDateRange[0] || this.dateRange.end !== this.selectedDateRange[1]) {
			this.dateRange = {
				start: this.selectedDateRange[0],
				end: this.selectedDateRange[1]
			};

			sessionStorage.setItem('totals.dateRange', JSON.stringify(this.selectedDateRange));

			this.setDateRangeDisplayValue();
			this.dateRangeChange.emit(this.dateRange);
		}

		if (this.dropdown) {
			this.dropdown.hide();
		}
	}

	onCancel() {
		if (this.dropdown) {
			this.dropdown.hide();
		}
	}

	@HostListener('document:click', ['$event'])
	onClick(event) {
		if (!this.element.nativeElement.contains(event.target) && event.target.classList.toString().indexOf('owl-dt-calendar') === -1) {
			this.dropdown.hide();
		}
	}
}
