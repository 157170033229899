<footer class="d-none d-sm-block footer">
	<div class="container">
		<div class="row">
			<div class="col-6 d-flex flex-column align-items-center align-items-sm-start justify-content-center">
				<a [href]="landingPageUrl">
					<img class="img-fluid logo mr-2" src="/assets/images/teamtime-logo.svg" alt="teamtime logo" />
					<img class="img-fluid brand d-none d-sm-inline-block" src="/assets/images/teamtime-brand.svg" alt="teamtime brand" />
				</a>
			</div>
			<div class="col-12 d-flex align-items-center justify-content-center">
				<a class="text-secondary" href="https://www.clearbluedesign.com" target="_blank">© Clear Blue Design</a>
				<!-- <div class="d-flex flex-column align-items-center">
					<div class="small text-muted">
						made with love for
					</div>

					<a class="mx-4" href="https://basecamp.com?ref=teamtime" target="_blank">
						<img class="img-fluid" src="/assets/images/basecamp-brand.svg" alt="Basecamp" />
					</a>
				</div> -->
			</div>
			<div class="col-6 d-flex flex-column align-items-center align-items-sm-end justify-content-center">
				<div class="d-flex align-items-center">
					<a class="mr-1" href="https://www.instagram.com/clearbluedesign" target="_blank">
						<img class="img-fluid" src="/assets/images/instagram.png" alt="Instagram" />
					</a>

					<a class="mr-1" href="https://itunes.apple.com/us/app/apple-store/id1222445133?mt=8" target="_blank">
						<img style="height: 29px;" src="/assets/apple-appstore-badge.svg" alt="Download on the App Store" />
					</a>
	
					<a href="https://play.google.com/store/apps/details?id=com.clearbluedesign.teamtime" target="_blank">
						<img src="/assets/google-play-badge.svg" alt="Get it on Google Play" />
					</a>
				</div>
			</div>
		</div>
	</div>
</footer>
