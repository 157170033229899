import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, BehaviorSubject, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { trace } from '@angular/fire/performance';

import { CookieService } from 'ngx-cookie-service';

import { environment } from '@myapp/environment';

@Injectable({ providedIn: 'root' })
export class AuthService {
	private urlPrefix: string = environment.rest.apiHost + '/v1/auth';
	private authenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	get accessTokenKeyName(): string {
		return 'bcAccessToken';
	}

	get refreshTokenKeyName(): string {
		return 'bcRefreshToken';
	}

	get expiresKeyName(): string {
		return 'bcTokenExpires';
	}

	get token(): string {
		let now = new Date();
		let expires = new Date(localStorage.getItem(this.expiresKeyName));

		if (expires > now) {
			return localStorage.getItem(this.accessTokenKeyName);
		}

		return null;
	}

	get isAuthenticated(): boolean {
		if (this.token && this.token != "null") {
			return true;
		}

		return false;
	}

	constructor(
		private http: HttpClient,
		private cookies: CookieService
	) {
	}

	authenticate(code: string): Observable<any> {
		return this.http
			.post(this.urlPrefix + '/token', { code: code })
			.pipe(
				trace('get-auth-token'),
				tap(
					response => {
						if (response.success) {
							this.storeTokens(response.data);
						}
					},

					response => {
						console.error(response);
					}
				)
			);
	}

	refresh(): Observable<any> {
		let bcRefreshToken = localStorage.getItem(this.refreshTokenKeyName);

		if (bcRefreshToken) {
			return this.http
				.post(this.urlPrefix + '/refresh', { refreshToken: bcRefreshToken })
				.pipe(
					trace('refresh-auth-token'),
					tap(
						response => {
							if (response.success) {
								this.storeTokens(response.data);
							}
						},

						response => {
							console.error(response);
						}
					)
				);
		}

		return of(null);
	}

	deauthenticate() {
		localStorage.removeItem(this.accessTokenKeyName);
		localStorage.removeItem(this.refreshTokenKeyName);

		this.cookies.delete(environment.landinPage.cookieName, '/', environment.landinPage.cookieDomain);

		this.authenticated.next(false);
	}

	change(): Observable<boolean> {
		return this.authenticated.asObservable();
	}

	private storeTokens(data: any): void {
		if (data.access_token) {
			let bcAccessTokenExpires = new Date();

			bcAccessTokenExpires.setTime(bcAccessTokenExpires.getTime() + (data.expires_in * 1000));

			localStorage.setItem(this.accessTokenKeyName, data.access_token);
			localStorage.setItem(this.expiresKeyName, bcAccessTokenExpires.toISOString());

			this.cookies.set(environment.landinPage.cookieName, 'true', bcAccessTokenExpires, '/', environment.landinPage.cookieDomain);

			this.authenticated.next(true);
		}

		if (data.refresh_token) {
			localStorage.setItem(this.refreshTokenKeyName, data.refresh_token);
		}
	}
}
