import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";

import { trace } from "@angular/fire/performance";

import { UnpaidTime, Payment, ProjectTime } from "@myapp/portal/models";
import { environment } from "@myapp/environment";

@Injectable({ providedIn: "root" })
export class PaymentsService {
	private urlPrefix: string = environment.rest.apiHost + "/v2/payments";

	constructor(private http: HttpClient) { }

	getUnpaidTime(teamID: number, fromDate: Date, toDate: Date) {
		let options = {
			params: new HttpParams({
				fromObject: {
					teamID: teamID,
					fromLocal: this.getDate(fromDate),
					toLocal: this.getDate(toDate),
				},
			}),
		};

		return this.http
			.get<ResourceResponse<UnpaidTime[]>>(`${this.urlPrefix}/unpaid`, options)
			.pipe(trace("payments-unpaid"));
	}

	getPastPayments(teamID: number, page: number) {
		let options = {
			params: new HttpParams({
				fromObject: {
					teamID: teamID,
					page: page
				},
			}),
		};

		return this.http
			.get<ResourceResponse<Payment[]>>(`${this.urlPrefix}/paid`, options)
			.pipe(trace("payments-paid"));
	}

	getPaymentProjectsTime(teamID: number, paymentID: number) {
		let options = {
			params: new HttpParams({
				fromObject: {
					teamID: teamID
				},
			}),
		};

		return this.http
			.get<ResourceResponse<ProjectTime[]>>(`${this.urlPrefix}/${paymentID}/time`, options)
			.pipe(trace("payments-details"));
	}

	payForTime(
		teamID: number,
		teamUserID: number,
		fromLocal: string,
		toLocal: string,
		expectedTotalMinutes: number,
		expectedTotalProjects: number,
		notes: string
	) {
		return this.http
			.post<ResourceResponse<Payment>>(
				`${this.urlPrefix}`,
				{
					teamID: teamID,
					teamUserID: teamUserID,
					fromLocal: fromLocal,
					toLocal: toLocal,
					expectedTotalMinutes: expectedTotalMinutes,
					expectedTotalProjects: expectedTotalProjects,
					notes: notes
				}
			)
			.pipe(trace("payments-markpaid"));
	}

	deletePayment(teamID: number, paymentID: number) {
		let options = {
			params: new HttpParams({
				fromObject: {
					teamID: teamID
				},
			}),
		};

		return this.http
			.delete<ResourceResponse<boolean>>(`${this.urlPrefix}/${paymentID}`, options)
			.pipe(trace("payments-delete"));
	}

	private getDate(date: Date): string {
		if (!date) {
			return null;
		}

		return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
	}
}
