<div class="spinner-fullscreen d-flex justify-content-center align-items-center flex-column my-5 pb-5" [class.flex-sm-row]="!message">
	<svg class="logo" xmlns="http://www.w3.org/2000/svg" width="68" height="68" viewBox="0 0 68 68" fill="none">
		<g id="spinnerlogo">
			<path id="outerCircle" opacity="1" d="M65.8029 33.9141C65.8029 51.5496 51.5065 65.846 33.8709 65.846C28.6572 65.846 23.7353 64.5965 19.3884 62.3806M33.8709 1.98211C45.2331 1.98211 55.2092 7.91643 60.8689 16.8547M10.6327 55.8148C5.24261 50.0976 1.93897 42.3916 1.93897 33.9141C1.93897 30.0556 2.62333 26.3569 3.87729 22.9329" stroke="#7ab892" stroke-width="2.66654" stroke-linecap="round" />
			<path id="innerCircle" opacity="1" d="M33.8705 10.7269C21.0646 10.7269 10.6834 21.1082 10.6834 33.914C10.6834 37.6109 11.5485 41.1057 13.0874 44.207M27.0279 56.075C29.1908 56.742 31.4887 57.1012 33.8705 57.1012C42.0026 57.1012 49.157 52.9148 53.296 46.5796" stroke="#008c3f" stroke-width="2.66654" stroke-linecap="round" />
			<path id="innermostCircle" d="M20.0239 33.914C20.0239 26.267 26.223 20.0679 33.8699 20.0679C37.4605 20.0679 40.7298 21.4333 43.1907 23.6749C44.5759 24.9366 45.7037 26.475 46.4868 28.2017C47.276 29.942 47.716 31.8753 47.716 33.914C47.716 36.7063 46.8904 39.3028 45.4704 41.4764C42.9971 45.2619 38.725 47.76 33.8699 47.76C32.446 47.76 31.074 47.5453 29.7836 47.1474C27.8457 46.5497 26.0884 45.5375 24.6156 44.2131C23.3178 43.0462 22.242 41.6379 21.4589 40.0597C20.5407 38.2091 20.0239 36.1233 20.0239 33.914Z" stroke="#087E2A" fill="#087E2A" stroke-width="1.98747" />
			<path id="hoursHand" d="M34 34L38 37" stroke="#ffffff" stroke-width="1.98747" stroke-linecap="round" />
			<path id="minutesHand" d="M34 26V34" stroke="#ffffff" stroke-width="1.98747" stroke-linecap="round" />
		</g>
	</svg>

	<img class="brand" src="/assets/images/teamtime-brand.svg" alt="teamtime brand" *ngIf="!message" />
	<div class="h3 text-center mt-4 px-4" [innerHTML]="message" *ngIf="message"></div>
</div>
