import { Component, OnInit, Optional } from '@angular/core';
import { Routes, Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';

import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService, ActiveToast } from 'ngx-toastr';
// TODO: REMOVE IF DECIDED NOT TO USE GTM
import { GoogleTagManagerService } from 'angular-google-tag-manager';

import { UpdateComponent } from '@myapp/shared/components';

import * as version from '../version.json';
import { environment } from '../environments/environment';

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
	private build: string = version.default.build;

	private noConnectionToast: ActiveToast<any>;

	private updateModalRef: BsModalRef;

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private modalService: BsModalService,
		private toastr: ToastrService,
		private updates: SwUpdate,
		// TODO: REMOVE IF DECIDED NOT TO USE GTM
		@Optional() private tagManager?: GoogleTagManagerService,
	) {
		this.initPageTrackingTags();
	}

	ngOnInit() {
		document.body.setAttribute('build', this.build);

		if (this.updates.isEnabled) {
			this.router.events.subscribe(event => {
				if (event instanceof NavigationEnd) {
					let childPath = undefined;

					if (this.route.snapshot.firstChild && this.route.snapshot.firstChild.url[0]) {
						childPath = this.route.snapshot.firstChild.url[0].path;
					}

					this.updates.checkForUpdate();
					// TODO: THIS SUBSCRIBE SHOULD BE OUTSIDE OF THE ROUTE EVENT HANLDER - TOO MANY SUBS
					this.updates.available.subscribe(event => {
						console.log(`PWA Update Available: ${event.current.hash} -> ${event.available.hash}`);

						if (!this.updateModalRef) {
							this.updateModalRef = this.modalService.show(UpdateComponent, {
								ignoreBackdropClick: true,
								keyboard: false,
								backdrop: 'static'
							});
						}
					});
				}
			})
		}

		window.addEventListener('online', () => {
			if (this.noConnectionToast) {
				this.toastr.clear(this.noConnectionToast.toastId);
				this.noConnectionToast = null;
			}

			this.toastr.success('Internet Connection is Back!');
		});

		window.addEventListener('offline', () => {
			if (!this.noConnectionToast) {
				this.noConnectionToast = this.toastr.error('No Internet Connection', null, {
					disableTimeOut: true,
					tapToDismiss: false
				});
			}
		});
	}

	private initPageTrackingTags() {
		if (this.tagManager) {
			this.router.events.forEach(event => {
				if (event instanceof NavigationEnd) {
					const tag = {
						event: environment.googleTagManager.tags.pageView,
						pageName: event.url
					};
					this.tagManager.pushTag(tag);
				}
			});
		}
	}

	onReload() {
		location.reload();
	}
}
