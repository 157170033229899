import { Injectable } from '@angular/core';
import { HttpRequest, HttpInterceptor, HttpHandler, HttpEvent, HttpErrorResponse, HTTP_INTERCEPTORS } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';

import { AuthService } from '@myapp/core/services';

import { environment } from '@myapp/environment';


@Injectable()
export class RefreshInterceptor implements HttpInterceptor {
	constructor(
		private authService: AuthService
	) {
	}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (request.url.startsWith(environment.rest.apiHost) && !request.url.match(/\/api\/v[\d+]\/auth\/refresh/)) {
			let now = Date.now();
			let tokenExpires = Date.parse(localStorage.getItem(this.authService.expiresKeyName));

			if (tokenExpires < now) {
				return this.authService.refresh().pipe(
					switchMap(response => {
						return this.setAuthenticationHeader(request, next);
					}),

					catchError(error => {
						if (error instanceof HttpErrorResponse) {
							return this.setAuthenticationHeader(request, next);
						}

						return Observable.throw(error);
					})
				);
			}
		}

		// Continue with original request
		return next.handle(request);
	}

	private setAuthenticationHeader(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		let clone = request.clone({
			setHeaders: {
				Authorization: `Basecamp ${this.authService.token}`
			}
		});

		return next.handle(clone);
	}
}

export const RefreshInterceptorProvider = {
	provide: HTTP_INTERCEPTORS,
	useClass: RefreshInterceptor,
	multi: true
};
