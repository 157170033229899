import { Component } from '@angular/core';

import { environment } from '@myapp/environment';

@Component({
	selector: 'app-footer',
	templateUrl: 'footer.component.html',
	styleUrls: ['footer.component.scss']
})
export class FooterComponent {
	landingPageUrl: string = environment.landinPage.url;
}
