import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { formatDate } from '@angular/common';

import { Subscription } from 'rxjs';

import { SettingsService } from '@myapp/portal/services';

import * as moment from 'moment';

@Component({
	selector: 'app-calendar',
	templateUrl: 'calendar.component.html',
	styleUrls: ['calendar.component.scss']
})
export class CalendarComponent implements OnInit, OnDestroy {
	private settings$: Subscription;

	private dateInternal = new Date();
	private touchClientX: number = null;

	loading: boolean = false;

	dates: Date[] = [];

	@Input()
	id: string = 'weekCalendar';

	@Input()
	get date(): Date {
		return this.dateInternal;
	}
	set date(value: Date) {
		this.dateInternal = value;
		this.fillDates();
	}

	@Output()
	dateChange: EventEmitter<Date> = new EventEmitter<Date>();

	firstDayOfWeek: Date;
	lastDayOfWeek: Date;

	@Input()
	max: Date;

	@Input()
	weekOnly: boolean = false;

	@Input()
	fill: { [key: string]: number };

	constructor(
		private settingsService: SettingsService
	) {
	}

	ngOnInit() {
		this.loading = true;

		this.settings$ = this.settingsService.userSettings.subscribe(settings => {
			this.loading = false;

			if (settings) {
				let setting = settings.find(m => m.constantName == 'FirstDayOfWeek');
				let settingValue = JSON.parse(setting.settingDetails);

				let weekStartingDay = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
					.indexOf(settingValue);

				moment.locale('en', {
					week: {
						dow: weekStartingDay
					}
				});
				moment.locale('en');

				this.fillDates();
			}
		});
	}

	ngOnDestroy() {
		if (this.settings$) {
			this.settings$.unsubscribe();
		}
	}

	fillDates() {
		this.firstDayOfWeek = moment(this.date).startOf('week').toDate();
		this.lastDayOfWeek = moment(this.firstDayOfWeek).add(6, 'days').toDate();

		this.dates = [];
		for (let i = 0; i < 7; i++) {
			this.dates.push(moment(this.firstDayOfWeek).add(i, 'days').toDate());
		}

		if (!this.weekOnly) {
			this.dates.unshift(moment(this.firstDayOfWeek).add(-1, 'days').toDate());
			this.dates.push(moment(this.lastDayOfWeek).add(1, 'days').toDate());
		}
	}

	isActive(date: Date): boolean {
		if (formatDate(this.date, 'yyyyMMdd', 'en-US') == formatDate(date, 'yyyyMMdd', 'en-US')) {
			return true;
		}

		return false;
	}

	isDisabled(date: Date): boolean {
		if (date > this.max) {
			return true;
		}

		return false;
	}

	isCurrentWeek(date: Date): boolean {
		if (date >= this.firstDayOfWeek && date <= this.lastDayOfWeek) {
			return true;
		}

		return false;
	}

	onSelect(date: Date): void {
		if (date > this.max) {
			date = this.max;
		}

		if (date != this.date) {
			this.date = date;
			this.fillDates();

			this.dateChange.emit(this.date);
		}
	}

	onShowWeek(multiplier: number): void {
		this.onSelect(moment(this.date).add(multiplier * 7, 'days').toDate());
	}

	getFillStyle(date: Date): any {
		let dateString = formatDate(date, 'yyyy-MM-dd', 'en-US');
		if (this.fill && this.fill[dateString]) {
			let percent = this.fill[dateString] || 0;

			if (percent > 100) {
				percent = 100;
			}

			return {
				'height': percent + '%'
			}
		}

		return null;
	}

	onSwipeStart(event: TouchEvent): void {
		this.touchClientX = event.touches[0].clientX;
	}

	onSwipeEnd(event: TouchEvent): void {
		let swipeDistance = event.changedTouches[0].clientX - this.touchClientX;
		let swipeDetla = Math.sign(swipeDistance);

		if (Math.abs(swipeDistance) > 0) {
			this.onShowWeek(swipeDetla > 0 ? -1 : 1);
		}
	}
}
