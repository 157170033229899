import { Injectable } from '@angular/core';
import { HttpRequest, HttpInterceptor, HttpHandler, HttpEvent, HTTP_INTERCEPTORS } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AuthService } from '@myapp/core/services';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	constructor(
		private authService: AuthService
	) {
	}

	public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (this.authService.isAuthenticated) {
			let clone = request.clone({
				setHeaders: {
					Authorization: `Basecamp ${this.authService.token}`
				}
			});

			return next.handle(clone);
		}

		return next.handle(request);
	}
}

export const AuthInterceptorProvider = {
	provide: HTTP_INTERCEPTORS,
	useClass: AuthInterceptor,
	multi: true
};
