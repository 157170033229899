<ng-container *ngIf="!loading">
	<div [attr.id]="id" class="calendar" (touchstart)="onSwipeStart($event)" (touchend)="onSwipeEnd($event)">
		<a [attr.id]="id + 'Prev'" class="calendar-arrow" href="javascript:void(0);" tooltip="< Previous week" placement="left" (click)="onShowWeek(-1)">
			<img src="/assets/images/calendar-arrow-left.svg" alt="<" />
		</a>

		<ng-container *ngFor="let d of dates">
			<a href="javascript:void(0);" class="day" [class.active]="isActive(d)" [class.current-week]="isCurrentWeek(d)" [class.disabled]="isDisabled(d)" (click)="onSelect(d)" data-test="calendar-day" [attr.data-test-date]="d | date:'d'">
				<span class="day-name">
					{{ d | date:'EEEEEE' }}
				</span>

				<span class="day-date">
					<span class="day-date-fill">
						<span class="day-date-fill-value" [style]="getFillStyle(d)"></span>
					</span>

					{{ d | date:'d' }}
				</span>
			</a>
		</ng-container>

		<a [attr.id]="id + 'Next'" class="calendar-arrow" href="javascript:void(0);" tooltip="Next week >" placement="right" (click)="onShowWeek(+1)">
			<img src="/assets/images/calendar-arrow-right.svg" alt=">" />
		</a>
	</div>
</ng-container>

<ng-container *ngIf="loading">
	<div class="calendar-loader">
		<ngx-content-loading [width]="640" [height]="94">
			<svg:g ngx-rect width="15" height="24" x="10" y="35"></svg:g>
			<ng-container *ngFor="let index of [0, 1, 2, 3, 4, 5, 6, 7, 8]">
				<svg:g ngx-rect width="30" height="19" [x]="50 + (42 + 22) * index" y="17"></svg:g>
				<svg:g ngx-circle cy="60" [cx]="50 + 14 + (42 + 22) * index" r="16"></svg:g>
			</ng-container>
			<svg:g ngx-rect width="15" height="24" x="615" y="35"></svg:g>
		</ngx-content-loading>
	</div>
</ng-container>
