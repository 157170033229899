import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable, BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { trace } from '@angular/fire/performance';
import { AngularFireAnalytics } from '@angular/fire/analytics';

import { AuthService } from '@myapp/core';

import { Profile, Identity, Team } from '@myapp/portal/models';

import { environment } from '@myapp/environment';

@Injectable({ providedIn: 'root' })
export class UsersService {
	static FeatureNames = {
		Approvals: 'approvals',
		Payments: 'payments',
		ApprovalsDetails: 'approvals-details',
		PaymentsDetails: 'payments-details',
		Notes: 'notes'
	};

	private urlPrefix: string = environment.rest.apiHost + '/v1/users';

	private profile$: BehaviorSubject<Profile> = new BehaviorSubject<Profile>(null);
	private identity$: BehaviorSubject<Identity> = new BehaviorSubject<Identity>(null);
	private teams$: BehaviorSubject<Team[]> = new BehaviorSubject<Team[]>(null);
	private selectedTeam$: BehaviorSubject<Team> = new BehaviorSubject<Team>(null);
	private impersonateAs$: BehaviorSubject<Profile> = new BehaviorSubject<Profile>(null);

	private _identityChecked: boolean = false;
	get identityChecked(): boolean {
		return this._identityChecked;
	}

	private _selectedTeamChecked: boolean = false;
	get selectedTeamChecked(): boolean {
		return this._selectedTeamChecked;
	}

	// TODO: WHAT ARE THESE ***CHECKED FIELDS FOR???
	private _profileChecked: boolean = false;
	get profileChecked(): boolean {
		return this._profileChecked;
	}

	private userIDStorageKey = 'userID';
	private lastSelectedTeamIDStorageKey = 'lastSelectedTeamID';

	get impersonate(): Profile {
		return this.impersonateAs$.value;
	}

	get impersonate$(): Observable<Profile> {
		return this.impersonateAs$.asObservable();
	}

	// TODO: REMOVE ONCE GLOBAL SUBSCRIPTION STATE AVAILABLE
	triggerSelectedTeamChange() {
		this.selectedTeam$.next(this.selectedTeam$.value);
	}

	constructor(
		private authService: AuthService,
		private http: HttpClient,
		private analytics: AngularFireAnalytics
	) {
		this.authService.change().subscribe(isAuthenticated => {
			this._identityChecked = false;
			this._profileChecked = false;

			if (this.authService.isAuthenticated) {
				this.getIdentity().subscribe(response => {
					this._identityChecked = true;

					// set google analytics userid
					if (response.success) {
						analytics.setUserId(`${response.data.userID}`);

						localStorage.setItem(this.userIDStorageKey, `${response.data.userID}`);
					} else {
						analytics.setUserId(null);

						localStorage.removeItem(this.userIDStorageKey);
					}
				});
			} else {
				this._identityChecked = true;

				this.identity$.next(null);
				this.profile$.next(null);
				this.teams$.next(null);
				this.selectedTeam$.next(null);

				localStorage.removeItem(this.userIDStorageKey);
				localStorage.removeItem(this.lastSelectedTeamIDStorageKey);

				// clear google analytics userid
				analytics.setUserId(null);
			}
		});
	}

	private getProfile(team: Team): void {
		this.profile$.next(null);

		this.http
			.get<ResourceResponse<Profile>>(this.urlPrefix + '/' + team.teamID + '/profile')
			.pipe(trace('get-profile'))
			.subscribe(response => {
				if (response.success) {
					this._profileChecked = true;
					this.profile$.next(response.data);
				}
			});
	}

	private getIdentity(): Observable<ResourceResponse<Identity>> {
		return this.http
			.get<ResourceResponse<Identity>>(this.urlPrefix + '/identity')
			.pipe(
				trace('get-identity'),
				tap(
					response => {
						this._identityChecked = true;

						if (response.success) {
							this.identity$.next(response.data);
							this.teams$.next(response.data.teams);

							let lastSelectedTeam = null;
							let lastSelectedTeamID = localStorage.getItem(this.lastSelectedTeamIDStorageKey);

							if (lastSelectedTeamID) {
								lastSelectedTeam = response.data.teams
									.find(team => team.teamID === parseInt(lastSelectedTeamID, 10));
							}

							this.selectTeam(lastSelectedTeam);
						}
					},

					response => {
						this._identityChecked = true;

						this.identity$.next(null);
						this.teams$.next(null);
						this.selectedTeam$.next(null);
					}
				)
			);
	}

	profile(): Observable<Profile> {
		return this.profile$.asObservable();
	}

	refreshProfile(teamID: number) {
		this.http
			.get<ResourceResponse<Profile>>(this.urlPrefix + '/' + teamID + '/profile')
			.pipe(trace('get-profile'))
			.subscribe(response => {
				if (response.success) {
					this._profileChecked = true;
					this.profile$.next(response.data);
				}
			});
	}

	identity(): Observable<Identity> {
		return this.identity$.asObservable();
	}

	teams(): Observable<Team[]> {
		return this.teams$.asObservable();
	}

	selectTeam(team: Team): void {
		if (team) {
			localStorage.setItem(this.lastSelectedTeamIDStorageKey, team.teamID.toString());

			this.getProfile(team);
		}

		this._selectedTeamChecked = true;
		this.selectedTeam$.next(team);
		this.impersonateAs$.next(null);
	}

	// TODO: MOVE ALL IMPERSNOTION LOGIC INTO TIMELOG PAGE - IT APPLIES ONLY TO TIMELOG PAGE (FOREVER)
	//	AND SHOULD AUTO CLEAR WHENEVER USER NAVIGATES AWAY FROM TIMELOG PAGE
	impersonateAs(member: Profile): void {
		if (member && this.profile$.value && (this.profile$.value.internalUserID == member.internalUserID || this.profile$.value.email == member.email)) {
			member = null;
		}

		this.impersonateAs$.next(member);
	}

	selectedTeam(): Observable<Team> {
		return this.selectedTeam$.asObservable();
	}

	refresh(): Observable<ResourceResponse<Identity>> {
		return this.getIdentity();
	}

	members(teamID: number): Observable<ResourceResponse<Profile[]>> {
		return this.http
			.get<ResourceResponse<Profile[]>>(`${this.urlPrefix}/${teamID}/members`)
			.pipe(trace('get-team-members'));
	}

	inviteAll(teamID: number): Observable<ResourceResponse<void>> {
		return this.http
			.post<ResourceResponse<void>>(`${this.urlPrefix}/${teamID}/invite`, {})
			.pipe(trace('invite-all'));
	}

	inviteMember(teamID: number, memberID: number): Observable<ResourceResponse<void>> {
		return this.http
			.post<ResourceResponse<void>>(`${this.urlPrefix}/${teamID}/invite/${memberID}`, {})
			.pipe(trace('invite-one'));
	}

	inviteAdmins(teamID: number): Observable<ResourceResponse<void>> {
		return this.http
			.post<ResourceResponse<void>>(`${this.urlPrefix}/${teamID}/invite-admins`, {})
			.pipe(trace('invite-admins'));
	}

	inviteByEmail(teamID: number, email: string): Observable<ResourceResponse<void>> {
		return this.http
			.post<ResourceResponse<void>>(`${this.urlPrefix}/${teamID}/invite-by-email`, { email: email })
			.pipe(trace('invite-email'));
	}

	deleteTeam(teamID: number): Observable<ResourceResponse<void>> {
		return this.http
			.delete<ResourceResponse<void>>(`${this.urlPrefix}/${teamID}`)
			.pipe(trace('delete-team'));
	}

	dismissFeature(featureName: string, teamID: number) {
		let options = {
			params: new HttpParams({
				fromObject: {
					featureName: featureName
				}
			})
		};

		return this.http
			.post<ResourceResponse<void>>(`${this.urlPrefix}/dismiss`, null, options)
			.pipe(
				trace('dismiss-feature'),
				tap(result => this.refreshProfile(teamID))
			);
	}
}
