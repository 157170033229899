import { Directive, Input, ViewContainerRef, TemplateRef } from "@angular/core";

import { UsersService } from '@myapp/portal/services';

@Directive({
	selector: '[ifAdmin]'
})
export class IfAdminDirective {
	private hasView: boolean = false;

	@Input('ifAdmin')
	set shown(value: boolean) {
		this.usersService.profile().subscribe(profile => {
			if (value === null) {
				value = true;
			}

			if (profile) {
				if (profile.isAdmin === !!value) {
					if (!this.hasView) {
						this.viewContainer.createEmbeddedView(this.template);
						this.hasView = true;
					}
				} else {
					this.viewContainer.clear();
					this.hasView = false;
				}
			}
		});
	}

	constructor(
		private usersService: UsersService,
		private template: TemplateRef<any>,
		private viewContainer: ViewContainerRef
	) {
	}
}
