import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { trace } from '@angular/fire/performance';

import { Setting, UserPaymentSetting } from '@myapp/portal/models';
import { UsersService } from './users.service';

import { environment } from '@myapp/environment';

@Injectable({ providedIn: 'root' })
export class SettingsService {
	private urlPrefix: string = environment.rest.apiHost + '/v2/settings';
	private teamID: number;

	private userSettings$: BehaviorSubject<Setting[]> = new BehaviorSubject<Setting[]>(null);
	private teamSettings$: BehaviorSubject<Setting[]> = new BehaviorSubject<Setting[]>(null);

	get userSettings(): Observable<Setting[]> {
		return this.userSettings$.asObservable();
	}

	get teamSettings(): Observable<Setting[]> {
		return this.teamSettings$.asObservable();
	}

	constructor(
		private usersService: UsersService,
		private http: HttpClient
	) {
		this.usersService.selectedTeam().subscribe(team => {
			if (team) {
				this.teamID = team.teamID;

				this.http.get<ResourceResponse<Setting[]>>(`${this.urlPrefix}/${team.teamID}/user-settings`).toPromise().then(response => {
					if (response.success) {
						this.userSettings$.next(response.data);
					}
				});

				this.http.get<ResourceResponse<Setting[]>>(`${this.urlPrefix}/${team.teamID}/team-settings`).toPromise().then(response => {
					if (response.success) {
						this.teamSettings$.next(response.data);
					}
				});
			}
		});
	}

	saveUserSettings(setting: Setting): Observable<ResourceResponse<Setting>> {
		return this.http.put<ResourceResponse<Setting>>(`${this.urlPrefix}/${this.teamID}/user-settings`, setting).pipe(
			trace('save-user-settings'),
			tap(response => {
				if (response.success) {
					let settings = this.userSettings$.value;
					let original = settings.find(m => m.constantName == setting.constantName)

					if (original) {
						original = Object.assign({}, original, setting);

						this.userSettings$.next(settings);
					}
				}
			})
		);
	}

	saveTeamSettings(setting: Setting): Observable<ResourceResponse<Setting>> {
		return this.http.put<ResourceResponse<Setting>>(`${this.urlPrefix}/${this.teamID}/team-settings`, setting).pipe(
			trace('save-team-settings'),
			tap(response => {
				if (response.success) {
					let settings = this.teamSettings$.value;
					let original = settings.find(m => m.constantName == setting.constantName)

					if (original) {
						original = Object.assign({}, original, setting);

						this.teamSettings$.next(settings);
					}
				}
			})
		);
	}

	getUserPaymentSettings(teamID: number) {
		let options = {
			params: new HttpParams({
				fromObject: {
					teamID: teamID
				},
			}),
		};

		return this.http
			.get<ResourceResponse<UserPaymentSetting[]>>(`${this.urlPrefix}/payment-users`, options)
			.pipe(trace('settings-userpayment'));
	}

	updatePaymentTrackingForUser(teamID: number, teamUserID: number, track?: boolean) {
		let options = {
			params: new HttpParams({
				fromObject: {
					teamID: teamID,
					track: track
				},
			}),
		};

		return this.http
			.put<ResourceResponse<boolean>>(`${this.urlPrefix}/payment-users/${teamUserID}`, null, options)
			.pipe(trace('settings-userpayment-update'));
	}

	trackPaymentsForEveryone(teamID: number) {
		let options = {
			params: new HttpParams({
				fromObject: {
					teamID: teamID
				},
			}),
		};

		return this.http
			.put<ResourceResponse<boolean>>(`${this.urlPrefix}/payment-users/all`, null, options)
			.pipe(trace('settings-userpayment-all'));
	}
}
