import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { trace } from '@angular/fire/performance';

import { UsersService } from '@myapp/portal/services/users.service';
import { Subscription, SubscriptionType, SubscriptionUpgrade, SubscriptionUpgradeOption, SubscriptionBill, SubscriptionPaymentMethod, SubscriptionPaymentMethodChange } from '@myapp/portal/models';

import { environment } from '@myapp/environment';

@Injectable({ providedIn: 'root' })
export class SubscriptionsService {
	private urlPrefix: string = environment.rest.apiHost + '/v1/subscriptions';

	private cachedCurrent: ResourceResponse<Subscription>;
	private cachedNext: ResourceResponse<SubscriptionUpgradeOption[]>;
	private cachedTypes: ResourceResponse<SubscriptionType[]>;

	constructor(
		private http: HttpClient,
		private usersService: UsersService
	) {
		this.usersService.selectedTeam().subscribe(team => {
			if (team) {
				this.cachedCurrent = null;
				this.cachedNext = null;
				this.cachedTypes = null;

				this.current(team.teamID);
				this.next(team.teamID);
				this.paymentMethod(team.teamID);
				this.types();
			}
		});
	}

	current(teamID: number): Observable<ResourceResponse<Subscription>> {
		if (!this.cachedCurrent) {
			return this.http
				.get<ResourceResponse<Subscription>>(`${this.urlPrefix}/${teamID}/current`)
				.pipe(
					trace('get-team-subscription'),
					tap(response => {
						if (response.success) {
							this.cachedCurrent = response;
						}
					})
				);
		} else {
			return of(this.cachedCurrent);
		}
	}

	next(teamID: number): Observable<ResourceResponse<SubscriptionUpgradeOption[]>> {
		if (!this.cachedNext) {
			return this.http
				.get<ResourceResponse<SubscriptionUpgradeOption[]>>(`${this.urlPrefix}/${teamID}/next`)
				.pipe(
					trace('get-upgrade-option'),
					tap(response => {
						if (response.success) {
							this.cachedNext = response;
						}
					})
				);
		} else {
			return of(this.cachedNext);
		}
	}

	upcoming(teamID: number): Observable<ResourceResponse<SubscriptionBill>> {
		return this.http
			.get<ResourceResponse<SubscriptionBill>>(`${this.urlPrefix}/${teamID}/upcoming`)
			.pipe(trace('get-upcoming-bill'));
	}

	history(teamID: number): Observable<ResourceResponse<SubscriptionBill[]>> {
		return this.http
			.get<ResourceResponse<SubscriptionBill[]>>(`${this.urlPrefix}/${teamID}/billing-history`)
			.pipe(trace('get-billing-history'));
	}

	paymentMethod(teamID: number): Observable<ResourceResponse<SubscriptionPaymentMethod>> {
		return this.http
			.get<ResourceResponse<SubscriptionPaymentMethod>>(`${this.urlPrefix}/${teamID}/payment-method`)
			.pipe(trace('get-payment-method'));
	}

	changePaymentMethod(teamID: number, model: SubscriptionPaymentMethodChange): Observable<ResourceResponse<SubscriptionPaymentMethod>> {
		return this.http
			.put<ResourceResponse<SubscriptionPaymentMethod>>(`${this.urlPrefix}/${teamID}/payment-method`, model)
			.pipe(trace('change-payment-method'));
	}

	initialize(teamID: number): Observable<ResourceResponse<any>> {
		return this.http
			.post<ResourceResponse<any>>(`${this.urlPrefix}/${teamID}/initialize`, {})
			.pipe(
				trace('init-subscription'),
				tap(response => {
					this.cachedCurrent = null;
					this.cachedNext = null;
				})
			);
	}

	upgrade(teamID: number, model: SubscriptionUpgrade): Observable<ResourceResponse<any>> {
		return this.http
			.post<ResourceResponse<any>>(`${this.urlPrefix}/${teamID}/upgrade`, model)
			.pipe(
				trace('upgrade-subscription'),
				tap(response => {
					this.cachedCurrent = null;
					this.cachedNext = null;
				})
			);
	}

	cancel(teamID: number): Observable<ResourceResponse<any>> {
		return this.http
			.post<ResourceResponse<any>>(`${this.urlPrefix}/${teamID}/cancel`, {})
			.pipe(
				trace('cancel-subscription'),
				tap(response => {
					this.cachedCurrent = null;
					this.cachedNext = null;
				})
			);
	}

	types(): Observable<ResourceResponse<SubscriptionType[]>> {
		if (!this.cachedTypes) {
			return this.http
				.get<ResourceResponse<SubscriptionType[]>>(`${this.urlPrefix}`)
				.pipe(
					trace('get-subscription-types'),
					tap(response => {
						if (response.success) {
							this.cachedTypes = response;
						}
					})
				);
		} else {
			return of(this.cachedTypes);
		}
	}
}
