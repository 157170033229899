import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { trace } from '@angular/fire/performance';

import { UsersService } from '@myapp/portal/services/users.service';
import { UserTime, ActiveTimer, Profile } from '@myapp/portal/models';

import { environment } from '@myapp/environment';

@Injectable({ providedIn: 'root' })
export class TimerService {
	private urlPrefix: string = 'timer';
	private teamID: number;
	private impersonatedProfile: Profile;

	constructor(
		private usersService: UsersService,
		private http: HttpClient
	) {
		this.usersService.selectedTeam().subscribe(team => {
			if (team) {
				this.teamID = team.teamID;
			}
		});

		this.usersService.impersonate$.subscribe(profile => {
			this.impersonatedProfile = profile;
		});
	}

	private getUrl(path: string, version: string = 'v1'): string {
		return `${environment.rest.apiHost}/${version}/${this.urlPrefix}/${path.replace('/', '')}`;
	}

	getActiveTimer(): Observable<ResourceResponse<ActiveTimer>> {
		if (this.teamID) {
			let queryParams: any = {
				teamID: this.teamID.toString()
			};

			if (this.impersonatedProfile) {
				queryParams.userID = this.impersonatedProfile.internalUserID;
			}

			let options = {
				params: new HttpParams({
					fromObject: queryParams
				})
			};

			return this.http
				.get<ResourceResponse<ActiveTimer>>(this.getUrl('', 'v2'), options)
				.pipe(trace('get-active-timer'));
		}

		return null;
	}

	startTimer(teamID: number, projectID: number, todoListID: number, timeToAdd: number = 0, note: string = null): Observable<ResourceResponse<UserTime>> {
		let queryParams: any = {
			projectID: projectID.toString(),
			todoListID: todoListID?.toString() || null,
			utcOffset: (new Date().getTimezoneOffset() / -60).toString(),
			timeToAdd: timeToAdd.toString()
		};

		if (this.impersonatedProfile) {
			queryParams.userID = this.impersonatedProfile.internalUserID;
		}

		let options = {
			params: new HttpParams({
				fromObject: queryParams
			})
		};

		let body = {
			projectID: projectID.toString(),
			todoListID: todoListID?.toString() || null,
			timeToAdd: timeToAdd.toString(),
			utcOffset: (new Date().getTimezoneOffset() / -60).toString(),
			note: note
		};

		return this.http
			.post<ResourceResponse<UserTime>>(this.getUrl(`/${teamID}/start`, 'v2'), body, options)
			.pipe(trace('timer-start'));
	}

	editAndStartTimer(teamID: number, originalProjectID: number, originalTodoListID: number, newProjectID: number, newTodoListID: number, totalTime: number): Observable<ResourceResponse<ActiveTimer>> {
		let queryParams: any = {
			utcOffset: (new Date().getTimezoneOffset() / -60).toString()
		};

		if (this.impersonatedProfile) {
			queryParams.userID = this.impersonatedProfile.internalUserID;
		}

		let options = {
			params: new HttpParams({
				fromObject: queryParams
			})
		};

		let body = {
			originalProjectID: originalProjectID.toString(),
			originalTodoListID: originalTodoListID?.toString() || null,
			newProjectID: newProjectID.toString(),
			newTodoListID: newTodoListID?.toString() || null,
			totalTime: totalTime.toString()
		};

		return this.http
			.post<ResourceResponse<ActiveTimer>>(`${this.urlPrefix}/${teamID}/edit-time/start-timer`, body, options)
			.pipe(trace('edit-timer'));
	}

	updateTimer(teamID: number, projectID: number, todoListID: number, timeToAdd: number, stop: boolean = false, note: string = null): Observable<ResourceResponse<UserTime>> {
		let queryParams: any = {
			projectID: projectID.toString(),
			todoListID: todoListID?.toString() || null,
			utcOffset: (new Date().getTimezoneOffset() / -60).toString(),
			timeToAdd: timeToAdd.toString(),
			stop: stop.toString()
		};

		if (this.impersonatedProfile) {
			queryParams.userID = this.impersonatedProfile.internalUserID;
		}

		let options = {
			params: new HttpParams({
				fromObject: queryParams
			})
		};

		let body = {
			projectID: projectID.toString(),
			todoListID: todoListID?.toString() || null,
			timeToAdd: timeToAdd.toString(),
			note: note
		};

		return this.http
			.put<ResourceResponse<UserTime>>(this.getUrl(`/${teamID}/update`, 'v2'), body, options)
			.pipe(trace('timer-update'));
	}

	stopTimer(teamID: number): Observable<ResourceResponse<UserTime>> {
		let queryParams: any = {
		};

		if (this.impersonatedProfile) {
			queryParams.userID = this.impersonatedProfile.internalUserID;
		}

		let options = {
			params: new HttpParams({
				fromObject: queryParams
			})
		};

		return this.http
			.put<ResourceResponse<UserTime>>(this.getUrl(`/${teamID}/stop`, 'v2'), null, options)
			.pipe(trace('timer-stop'));
	}
}
