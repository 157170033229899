import { AuthInterceptorProvider } from './interceptors/auth.interceptor';
import { RefreshInterceptorProvider } from './interceptors/refresh.interceptor';

export { AuthInterceptorProvider } from './interceptors/auth.interceptor';
export { RefreshInterceptorProvider } from './interceptors/refresh.interceptor';

export const INTERCEPTORS: any[] = [
	AuthInterceptorProvider,
	RefreshInterceptorProvider
];
