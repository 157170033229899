import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';

import { AngularFireModule } from '@angular/fire';
import {
	AngularFirePerformanceModule,
	PerformanceMonitoringService,
	DATA_COLLECTION_ENABLED,
	INSTRUMENTATION_ENABLED
} from '@angular/fire/performance';
import {
	AngularFireAnalyticsModule,
	ScreenTrackingService,
	COLLECTION_ENABLED
} from '@angular/fire/analytics';
// TODO: REMOVE IF DECIDED NOT TO USE GTM
import { GoogleTagManagerModule } from 'angular-google-tag-manager';

import { ToastrModule } from 'ngx-toastr';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';

import { CoreModule } from '@myapp/core';
import { SharedModule } from '@myapp/shared';

import { AppRoutingModule } from './app.routing';
import { AppComponent } from './app.component';

import { environment } from '@myapp/environment';

let firebaseModules = [
	AngularFireModule.initializeApp(environment.firebase),
	AngularFirePerformanceModule,
	AngularFireAnalyticsModule
];
let firebaseServices = [
	PerformanceMonitoringService,
	ScreenTrackingService,
	// enable firebase analytics/performance et al only for production
	{ provide: DATA_COLLECTION_ENABLED, useValue: environment.production },
	{ provide: INSTRUMENTATION_ENABLED, useValue: environment.production },
	{ provide: COLLECTION_ENABLED, useValue: environment.production }
];

@NgModule({
	declarations: [
		AppComponent
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,

		LoadingBarHttpClientModule,
		LoadingBarRouterModule,

		ServiceWorkerModule.register('ngsw-worker.js', environment.pwa),

		ToastrModule.forRoot({
			toastClass: 'ngx-toastr app-toast',
			positionClass: 'toast-top-center',
			enableHtml: true,
			preventDuplicates: true,
		}),

		...firebaseModules,
		// TODO: REMOVE IF DECIDED NOT TO USE GTM
		GoogleTagManagerModule.forRoot({ id: environment.googleTagManager.id }),

		CoreModule,
		SharedModule,

		AppRoutingModule
	],
	providers: [
		...firebaseServices
	],
	bootstrap: [
		AppComponent
	]
})
export class AppModule { }
