<div class="d-flex flex-column align-items-center">
	<div class="text-center text-muted my-5">
		<img class="img-fluid icon" src="/assets/images/icon-sad-clock.svg" alt="error icon" />

		<ng-container *ngIf="message">
			{{ message }}
		</ng-container>

		<ng-container *ngIf="!message">
			<ng-content></ng-content>
		</ng-container>
	</div>
</div>
