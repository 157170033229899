<div dropdown #dropdown="bs-dropdown" class="dropdown" [autoClose]="false" [insideClick]="true" [placement]="align">
	<div class="btn-group" role="group">
		<button type="button" class="btn btn-link" (click)="onSelectPrevDateRange()">
			<fa-icon [icon]="['fas', 'chevron-left']"></fa-icon>
		</button>

		<button dropdownToggle type="button" class="btn btn-tertiary rounded rounded-pill font-weight-bold text-nowrap px-3" [tooltip]="dateRangeTooltip" [disabled]="loading || disabled">
			<span>{{ dateRangeDisplay }}</span>
			<fa-icon class="ml-2" [icon]="['fas', 'angle-down']"></fa-icon>
		</button>

		<button type="button" class="btn btn-link" (click)="onSelectNextDateRange()" [disabled]="selectedDateRange[1] > today">
			<fa-icon [icon]="['fas', 'chevron-right']"></fa-icon>
		</button>
	</div>

	<ng-container *ngIf="!disabled">
		<div *dropdownMenu class="dropdown-menu" [class.dropdown-menu-right]="align == 'right'">
			<div class="date-range-presets">
				<div class="d-none d-sm-block list-group list-group-flush">
					<ng-container *ngFor="let dateRangePreset of dateRangePresets">
						<a class="list-group-item" href="javascript:void(0);" [class.active]="dateRangePreset.selected" (click)="onSelectDateRangePreset(dateRangePreset)">
							<span class="d-inline-block mr-auto">
								{{ dateRangePreset.label }}
							</span>

							<ng-container *ngIf="dateRangePreset.selected">
								<fa-icon [icon]="['far', 'check']"></fa-icon>
							</ng-container>
						</a>
					</ng-container>
				</div>

				<div class="d-block d-sm-none mb-3">
					<select class="custom-select" (change)="onSelectDateRangePresetByLabel($event.target.value)">
						<ng-container *ngFor="let dateRangePreset of dateRangePresets">
							<option [value]="dateRangePreset.label" [selected]="dateRangePreset.selected">
								{{ dateRangePreset.label }}
							</option>
						</ng-container>
					</select>
				</div>
			</div>

			<div class="date-range-calendar">
				<owl-date-time-inline [pickerType]="'calendar'"
									  [selectMode]="'range'"
									  [max]="maxDate"
									  [firstDayOfWeek]="weekStartingDay"
									  [(ngModel)]="selectedDateRange"
									  (ngModelChange)="onDateRangeChange($event)">
				</owl-date-time-inline>

				<div class="border-top text-right">
					<button type="button" class="btn btn-link text-dark mr-2 my-2" (click)="onCancel()">
						Cancel
					</button>

					<button type="button" class="btn btn-primary rounded rounded-pill mr-2 my-2" (click)="onApply()" [disabled]="!selectedDateRange[0] || !selectedDateRange[1]">
						Apply
					</button>
				</div>
			</div>
		</div>
	</ng-container>
</div>
