import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable, Subject, of } from 'rxjs';
import { trace } from '@angular/fire/performance';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';

import { TimeTotal, TimeLog, Project, ToDoList, AdjustTime, UserProject, UserTime, ActiveTimer } from '@myapp/portal/models';
import { UsersService } from '@myapp/portal/services/users.service';

import { environment } from '@myapp/environment';

import * as sample from '@myapp/portal/sample.json';

@Injectable({ providedIn: 'root' })
export class TimesService {
	private urlPrefix: string = 'times';
	private signalrUrl: string = environment.signalrUrl;

	private hubConnection: HubConnection;

	private sample: any = sample.default;

	constructor(
		private http: HttpClient,
		private usersService: UsersService
	) {
		// this.connectToSignalR();
	}

	private getUrl(path: string, version: string = 'v1'): string {
		return `${environment.rest.apiHost}/${version}/${this.urlPrefix}/${path.replace('/', '')}`;
	}

	private connectToSignalR() {
		this.hubConnection = new HubConnectionBuilder()
			.withUrl(this.signalrUrl)
			.build();

		this.hubConnection.start()
			.then(() => console.log(`SIGNALR => connection started`))
			.catch(err => console.log(`SIGNALR => error starting connection`, err));

		this.hubConnection.on('time-changed', data => {
			console.log(`SIGNALR => received time-changed`, data);
		});
	}

	private getDate(date: Date): string {
		return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
	}

	getUserTimeLogs(teamID: number, userID: number, startDate: Date, endDate: Date) {
		let options = {
			params: new HttpParams({
				fromObject: {
					startDate: this.getDate(startDate),
					endDate: this.getDate(endDate)
				}
			})
		};

		return this.http
			.get<ResourceResponse<TimeLog[]>>(this.getUrl(`/${teamID}/${userID}`, 'v2'), options)
			.pipe(trace('get-user-time-logs'));
	}

	getTeamTimeLogs(teamID: number, startDate: Date, endDate: Date) {
		let options = {
			params: new HttpParams({
				fromObject: {
					startDate: this.getDate(startDate),
					endDate: this.getDate(endDate)
				}
			})
		};

		return this.http
			.get<ResourceResponse<TimeLog[]>>(this.getUrl(`/${teamID}`, 'v2'), options)
			.pipe(trace('get-team-time-logs'));
	}

	projects(teamID: number, startDate: Date, endDate: Date) {
		let options = {
			params: new HttpParams({
				fromObject: {
					startDate: this.getDate(startDate),
					endDate: this.getDate(endDate)
				}
			})
		};

		return this.http
			.get<ResourceResponse<TimeTotal[]>>(this.getUrl(`/projects/${teamID}`, 'v1'), options)
			.pipe(trace('get-projects'));
	}

	projectsSample(): Observable<ResourceResponse<TimeTotal[]>> {
		return of({
			success: true,
			data: this.sample.projects
		});
	}

	toDoLists(teamID: number, projectID: number, startDate: Date, endDate: Date) {
		let options = {
			params: new HttpParams({
				fromObject: {
					startDate: this.getDate(startDate),
					endDate: this.getDate(endDate)
				}
			})
		};

		return this.http
			.get<ResourceResponse<TimeTotal[]>>(this.getUrl(`/projects/${teamID}/${projectID}`, 'v1'), options)
			.pipe(trace('get-todolists'));
	}

	toDoListsSample(projectID: number): Observable<ResourceResponse<TimeTotal[]>> {
		let project = this.sample.projects.find(m => m.id == projectID);

		return of({
			success: true,
			data: project.toDoLists
		});
	}

	toDoListUsers(teamID: number, projectID: number, toDoListID: number, startDate: Date, endDate: Date) {
		let options = {
			params: new HttpParams({
				fromObject: {
					startDate: this.getDate(startDate),
					endDate: this.getDate(endDate)
				}
			})
		};

		return this.http
			.get<ResourceResponse<TimeTotal[]>>(this.getUrl(`/projects/${teamID}/${projectID}/${toDoListID}`, 'v1'), options)
			.pipe(trace('get-todolist-users'));
	}

	toDoListUsersSample(projectID: number, toDoListID: number): Observable<ResourceResponse<TimeTotal[]>> {
		let project = this.sample.projects.find(m => m.id == projectID);
		let toDoList = project.toDoLists.find(m => m.id == toDoListID);

		return of({
			success: true,
			data: toDoList.users
		});
	}

	users(teamID: number, startDate: Date, endDate: Date) {
		let options = {
			params: new HttpParams({
				fromObject: {
					startDate: this.getDate(startDate),
					endDate: this.getDate(endDate)
				}
			})
		};

		return this.http
			.get<ResourceResponse<TimeTotal[]>>(this.getUrl(`/users/${teamID}`, 'v1'), options)
			.pipe(trace('get-users'));
	}

	usersSample(): Observable<ResourceResponse<TimeTotal[]>> {
		return of({
			success: true,
			data: this.sample.users
		});
	}

	userProjects(teamID: number, userID: number, startDate: Date, endDate: Date) {
		let options = {
			params: new HttpParams({
				fromObject: {
					startDate: this.getDate(startDate),
					endDate: this.getDate(endDate)
				}
			})
		};

		return this.http
			.get<ResourceResponse<TimeTotal[]>>(this.getUrl(`/users/${teamID}/${userID}`, 'v1'), options)
			.pipe(trace('get-user-projects'));
	}

	userProjectsSample(userID: number): Observable<ResourceResponse<TimeTotal[]>> {
		let user = this.sample.users.find(m => m.id == userID);

		return of({
			success: true,
			data: user.projects
		});
	}

	userProjectToDoLists(teamID: number, userID: number, projectID: number, startDate: Date, endDate: Date) {
		let options = {
			params: new HttpParams({
				fromObject: {
					startDate: this.getDate(startDate),
					endDate: this.getDate(endDate)
				}
			})
		};

		return this.http
			.get<ResourceResponse<TimeTotal[]>>(this.getUrl(`/users/${teamID}/${userID}/${projectID}`, 'v1'), options)
			.pipe(trace('get-user-project-todolists'));
	}

	userProjectToDoListsSample(userID: number, projectID: number): Observable<ResourceResponse<TimeTotal[]>> {
		let user = this.sample.users.find(m => m.id == userID);
		let project = user.projects.find(m => m.id == projectID);

		return of({
			success: true,
			data: project.toDoLists
		});
	}

	exportUserTotals(teamID: number, startDate: Date, endDate: Date) {
		let subject = new Subject<FileResponse>();

		let options: Object = {
			observe: 'response',
			responseType: 'ArrayBuffer',
			params: new HttpParams({
				fromObject: {
					startDate: this.getDate(startDate),
					endDate: this.getDate(endDate)
				}
			})
		};

		this.http
			.get<any>(this.getUrl(`/${teamID}/export-user`, 'v1'), options)
			.pipe(trace('export-user-totals'))
			.subscribe(
				response => {
					let contentDisposition = response.headers.get('Content-Disposition');
					let contentType = response.headers.get('Content-Type');
					let contentBody = new Blob([response.body], { type: contentType });

					let fileName = contentDisposition
						.split(';')[1]
						.trim()
						.split('=')[1]
						.replace(/"/g, '');

					let model = <FileResponse>{
						name: fileName,
						type: contentType,
						blob: contentBody
					};

					subject.next(model);
				},

				error => {
					subject.error(error);
				}
			);

		return subject.asObservable();
	}

	exportTeamTotals(teamID: number, startDate: Date, endDate: Date, allData: boolean = false) {
		let subject = new Subject<FileResponse>();

		let options: Object = {
			observe: 'response',
			responseType: 'ArrayBuffer',
			params: new HttpParams({
				fromObject: {
					startDate: this.getDate(startDate),
					endDate: this.getDate(endDate),
					allData: allData ? 'true' : 'false'
				}
			})
		};

		this.http
			.get<any>(this.getUrl(`/${teamID}/export-team`, 'v1'), options)
			.pipe(trace('export-team-totals'))
			.subscribe(
				response => {
					let contentDisposition = response.headers.get('Content-Disposition');
					let contentType = response.headers.get('Content-Type');
					let contentBody = new Blob([response.body], { type: contentType });

					let fileName = contentDisposition
						.split(';')[1]
						.trim()
						.split('=')[1]
						.replace(/"/g, '');

					let model = <FileResponse>{
						name: fileName,
						type: contentType,
						blob: contentBody
					};

					subject.next(model);
				},

				error => {
					subject.error(error);
				}
			);

		return subject.asObservable();
	}

	userProjectsList(teamID: number, startDate: Date, endDate: Date) {
		let options = {
			params: new HttpParams({
				fromObject: {
					startDate: this.getDate(startDate),
					endDate: this.getDate(endDate)
				}
			})
		};

		return this.http
			.get<ResourceResponse<Project[]>>(this.getUrl(`/${teamID}/user-projects`, 'v2'), options)
			.pipe(trace('get-user-projects'));
	}

	userProjectsToDoList(teamID: number, projectID: number, startDate: Date, endDate: Date) {
		let options = {
			params: new HttpParams({
				fromObject: {
					startDate: this.getDate(startDate),
					endDate: this.getDate(endDate)
				}
			})
		};

		return this.http
			.get<ResourceResponse<ToDoList[]>>(this.getUrl(`/${teamID}/user-projects/${projectID}`, 'v1'), options)
			.pipe(trace('get-user-project-todolists'));
	}

	adjustTime(teamID: number, startDate: Date, endDate: Date, model: AdjustTime[]): Observable<ResourceResponse<any>> {
		if (startDate == null) {
			startDate = new Date();
		}

		if (endDate == null) {
			endDate = new Date();
		}

		let options = {
			params: new HttpParams({
				fromObject: {
					startDate: this.getDate(startDate),
					endDate: this.getDate(endDate)
				}
			})
		};

		return this.http
			.post<ResourceResponse<any>>(this.getUrl(`/${teamID}/adjust-time`, 'v1'), model, options)
			.pipe(trace('adjust-timelogs'));
	}

	getDaysWithTime(teamID: number): Observable<ResourceResponse<ActiveTimer>> {
		return this.http
			.get<ResourceResponse<ActiveTimer>>(this.getUrl(`/${teamID}/days`, 'v1'))
			.pipe(trace('days'));
	}

	getTimeLogs(teamID: number, startDate: Date, endDate: Date): Observable<ResourceResponse<UserTime>> {
		let params: any = {
			startDate: this.getDate(startDate),
			endDate: this.getDate(endDate)
		};

		if (this.usersService.impersonate) {
			params.userID = this.usersService.impersonate.internalUserID;
		}

		let options = {
			params: new HttpParams({
				fromObject: params
			})
		};

		return this.http
			.get<ResourceResponse<UserTime>>(this.getUrl(`/${teamID}/time-logs`, 'v2'), options)
			.pipe(trace('get-time-logs'));
	}

	userRecentProjects(teamID: number, startDate: Date, endDate: Date) {
		let options = {
			params: new HttpParams({
				fromObject: {
					startDate: this.getDate(startDate),
					endDate: this.getDate(endDate)
				}
			})
		};

		return this.http
			.get<ResourceResponse<UserProject[]>>(this.getUrl(`/${teamID}/user-projects`, 'v2'), options)
			.pipe(trace('get-recent-projects'));
	}

	addTime(teamID: number, startDate: Date, projectID: number, todoListID: number, basecampProfileID: number, timeToAdd: number, note: string = null): Observable<ResourceResponse<UserTime>> {
		if (startDate == null) {
			startDate = new Date();
		}

		let params: any = {
			startDate: this.getDate(startDate)
		};

		if (this.usersService.impersonate) {
			params.userID = this.usersService.impersonate.internalUserID;
		}

		let options = {
			params: new HttpParams({
				fromObject: params
			})
		};

		let body = {
			projectID: projectID.toString(),
			todoListID: todoListID?.toString() || null,
			basecampProfileID: basecampProfileID?.toString() || null,
			timeToAdd: timeToAdd.toString(),
			startDate: startDate,
			note: note
		};

		return this.http
			.post<ResourceResponse<UserTime>>(this.getUrl(`/${teamID}/add-time`, 'v2'), body, options)
			.pipe(trace('add-time-log'));
	}

	editTime(teamID: number, startDate: Date, originalProjectID: number, originalTodoListID: number, newProjectID: number, newTodoListID: number, totalTime: number, note: string = null): Observable<ResourceResponse<UserTime>> {
		let params: any = {
			startDate: this.getDate(startDate)
		};

		if (this.usersService.impersonate) {
			params.userID = this.usersService.impersonate.internalUserID;
		}

		let options = {
			params: new HttpParams({
				fromObject: params
			})
		};

		let body = {
			originalProjectID: originalProjectID?.toString() || null,
			originalTodoListID: originalTodoListID?.toString() || null,
			newProjectID: newProjectID?.toString() || null,
			newTodoListID: newTodoListID?.toString() || null,
			totalTime: totalTime?.toString() || null,
			note: note
		};

		return this.http
			.post<ResourceResponse<UserTime>>(this.getUrl(`/${teamID}/edit-time`, 'v2'), body, options)
			.pipe(trace('edit-timer'));
	}

	deleteTime(teamID: number, startDate: Date, projectID: number, todoListID: number, userID: number = null): Observable<ResourceResponse<UserTime>> {
		let options = {
			params: new HttpParams({
				fromObject: {
					startDate: this.getDate(startDate),
				}
			})
		};

		let body = {
			projectID: projectID.toString(),
			todoListID: todoListID?.toString() || null,
			userID: userID?.toString() || null
		};

		if (this.usersService.impersonate) {
			body.userID = this.usersService.impersonate.internalUserID.toString();
		}

		return this.http
			.post<ResourceResponse<UserTime>>(this.getUrl(`/${teamID}/delete-time`, 'v2'), body, options)
			.pipe(trace('delete-timer'));
	}

	getMultiChartData(teamID: number, userID: number, startDate: Date, endDate: Date, groupBy: 'project' | 'user' = 'project'): Observable<ResourceResponse<any>> {
		let options = {
			params: new HttpParams({
				fromObject: {
					startDate: this.getDate(startDate),
					endDate: this.getDate(endDate),
					groupBy: groupBy
				}
			})
		};

		return this.http
			.get<ResourceResponse<any>>(this.getUrl(`/${teamID}/chart/${userID}`, 'v2'), options)
			.pipe(trace('get-multi-chart-data'));
	}
}
