import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { ModalModule } from 'ngx-bootstrap/modal';

import { HANDLERS } from './handlers';
import { INTERCEPTORS } from './interceptors';
import { GUARDS } from './guards';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,

		ModalModule.forRoot(),
	],
	providers: [
		HANDLERS,
		INTERCEPTORS,
		GUARDS
	]
})
export class CoreModule {
}
