import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { formatDate } from '@angular/common';

import { SubmissionReview, Submission, SubmissionsByWeek, SubmissionByProject, TimeLogsSubmissionStatus } from '@myapp/portal/models';

import { environment } from '@myapp/environment';

@Injectable({ providedIn: 'root' })
export class SubmissionsService {
	private urlPrefix: string = environment.rest.apiHost + '/v2/submissions';

	constructor(
		private http: HttpClient
	) {
	}

	submitWeekForApproval(teamUserID: number, from: Date, to: Date, expectedTotalMins: number) {
		let options = {
			params: new HttpParams({
				fromObject: {
					teamUserID: teamUserID,
					from: this.getDateOnly(from),
					to: this.getDateOnly(to),
					expectedTotalMins: expectedTotalMins
				}
			})
		};

		return this.http.post<ResourceResponse<number>>(this.urlPrefix, null, options);
	}

	getUnapprovedSubmissions(teamID: number) {
		let options = {
			params: new HttpParams({
				fromObject: {
					teamID: teamID
				}
			})
		};

		return this.http.get<ResourceResponse<SubmissionsByWeek[]>>(`${this.urlPrefix}/unapproved`, options);
	}

	getNotSubmittedTime(teamID: number) {
		let options = {
			params: new HttpParams({
				fromObject: {
					teamID: teamID
				}
			})
		};

		return this.http.get<ResourceResponse<SubmissionsByWeek[]>>(`${this.urlPrefix}/not-submitted`, options);
	}

	getPastSubmissions(teamID: number, page: number) {
		let options = {
			params: new HttpParams({
				fromObject: {
					teamID: teamID,
					page: page
				}
			})
		};

		return this.http.get<ResourceResponse<SubmissionsByWeek[]>>(`${this.urlPrefix}/approved`, options);
	}

	getUserSubmissionTimes(teamID: number, dateFrom: Date, dateTo: Date) {
		let options = {
			params: new HttpParams({
				fromObject: {
					teamID: teamID,
					from: this.getDateOnly(dateFrom),
					to: this.getDateOnly(dateTo)
				}
			})
		};

		return this.http.get<ResourceResponse<SubmissionsByWeek>>(`${this.urlPrefix}/review`, options);
	}

	getUserWeekTimeForReview(teamUserID: number, dateFrom: Date, dateTo: Date) {
		let options = {
			params: new HttpParams({
				fromObject: {
					teamUserID: teamUserID,
					from: this.getDateOnly(dateFrom),
					to: this.getDateOnly(dateTo)
				}
			})
		};

		return this.http.get<ResourceResponse<SubmissionReview>>(`${this.urlPrefix}/review`, options);
	}

	getSubmissionTime(teamUserID: number, submissionID: number, dateFrom?: Date, dateTo?: Date) {
		let params = {
			teamUserID: teamUserID
		};

		if (dateFrom && dateTo) {
			params['from'] = this.getDateOnly(dateFrom);
			params['to'] = this.getDateOnly(dateTo);
		}

		let options = {
			params: new HttpParams({
				fromObject: params
			})
		};

		return this.http.get<ResourceResponse<SubmissionByProject[]>>(`${this.urlPrefix}/${submissionID}/time`, options);
	}

	approveSubmission(teamID: number, submissionID: number) {
		let options = {
			params: new HttpParams({
				fromObject: {
					teamID: teamID
				}
			})
		};

		return this.http.put<ResourceResponse<SubmissionsByWeek[]>>(`${this.urlPrefix}/${submissionID}/approve`, null, options);
	}

	unapproveSubmission(teamID: number, submissionID: number) {
		let options = {
			params: new HttpParams({
				fromObject: {
					teamID: teamID
				}
			})
		};

		return this.http.put<ResourceResponse<SubmissionsByWeek[]>>(`${this.urlPrefix}/${submissionID}/unapprove`, null, options);
	}

	emailAboutSubmission(teamUserID: number, submissionID: number, dateFrom: Date, dateTo: Date, message: string) {
		let params = {
			teamUserID: teamUserID
		};

		if (dateFrom && dateTo) {
			params['from'] = this.getDateOnly(dateFrom);
			params['to'] = this.getDateOnly(dateTo);
		}

		let options = {
			params: new HttpParams({
				fromObject: params
			})
		};

		return this.http.put<ResourceResponse<Submission>>(`${this.urlPrefix}/${submissionID}/email`, { message: message }, options);
	}

	private getDateOnly(date: Date | string) {
		return formatDate(date, 'yyyy-MM-dd', 'en-US');
	}
}
