export const environment = {
	production: false,

	pwa: {
		enabled: true
	},

	rest: {
		apiHost: 'https://teamtime-api-dev.azurewebsites.net/api'
	},

	signalrUrl: 'https://cbd-signalr-test.service.signalr.net',

	landinPage: {
		url: 'https://dev.trackteamtime.com',
		cookieName: 'bcAuthorizedDev',
		cookieDomain: 'trackteamtime.com'
	},

	authentication: {
		authority: 'https://teamtime-api-dev.azurewebsites.net',

		cors: [
			'cbd-template-api.azurewebsites.net',
			'cbd-template-auth.azurewebsites.net'
		],

		authorize: {
			endPoint: '/connect/token',
			tokenKeyName: 'access_token',
			clientID: 'myapp',
			grantType: 'password',
			scope: 'openid profile offline_access myapp'
		},

		deauthorize: {
			endPoint: '/connect/endsession'
		},

		refresh: {
			endPoint: '/connect/endsession',
			tokenKeyName: 'refresh_token'
		}
	},

	captcha: {
		siteKey: '6LcGOMgUAAAAAIw2otgQdW0FGiQObwW7wb8wwY_4'
	},

	maps: {
		apiKey: 'AIzaSyC4loV7l4raULluJAzr9M_HphaPFwedHxY'
	},

	inactivity: {
		idle: 1800,
		timeout: 300,
		ping: 120
	},

	basecamp: {
		clientID: '16a282a4f080b5ea61c122a79b659a23f630c7a8',
		redirectUri: 'https://portaldev.trackteamtime.com/account/sign-in-callback'
	},

	stripe: {
		publicKey: 'pk_test_UlwJVnnZdEyHUW87LcHm6REd00nBVCD5fo'
	},

	firebase: {
		apiKey: 'AIzaSyAkKfshj_TGX_UXWBw3-xQmJsxC2ZhbDkk',
		authDomain: 'teamtime-100a5.firebaseapp.com',
		databaseURL: 'https://teamtime-100a5.firebaseio.com',
		projectId: 'teamtime-100a5',
		storageBucket: 'teamtime-100a5.appspot.com',
		messagingSenderId: '642866365187',
		appId: '1:642866365187:web:fe7093a3aa6696f41b5f36',
		measurementId: 'G-2Y7W8JH2HL'
	},

	analytics: {
		events: {
			signUp: 'sign_up',
			subscribe: 'subscribe'
		}
	},

	googleTagManager: {
		id: 'GTM-W848W9G',

		tags: {
			pageView: 'page'
		}
	},

	errorMessages: {
		errorSuffix: 'Try refreshing the page and let us know if the issue persists.'
	}
};

export enum PermissionType {
	Home_View = 1,
	Maintenance_View = 2
}
