import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ActivityLog } from '@myapp/portal/models';

import { environment } from '@myapp/environment';

@Injectable({ providedIn: 'root' })
export class LoggingService {
	private urlPrefix: string = environment.rest.apiHost + '/v1/logging';

	constructor(
		private http: HttpClient
	) {
	}

	logActivity(model: ActivityLog): void {
		this.http.post(`${this.urlPrefix}/activity`, model)
			.subscribe(_ => console.log(`Logged: ${model.type}`));
	}
}
