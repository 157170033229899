import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';

import { NgxContentLoadingModule } from 'ngx-content-loading';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';

import { COMPONENTS } from './components';
import { DIRECTIVES } from './directives';
import { PIPES } from './pipes';
import { ICONS } from './icons';

@NgModule({
	declarations: [
		COMPONENTS,
		DIRECTIVES,
		PIPES
	],
	imports: [
		CommonModule,
		FormsModule,
		RouterModule,
		HttpClientModule,

		NgxContentLoadingModule,
		FontAwesomeModule,
		TooltipModule,
		PopoverModule,
		BsDropdownModule,
		OwlDateTimeModule,
		OwlNativeDateTimeModule,
	],
	exports: [
		CommonModule,
		FormsModule,
		RouterModule,
		HttpClientModule,

		FontAwesomeModule,
		TooltipModule,
		PopoverModule,
		BsDropdownModule,
		OwlDateTimeModule,
		OwlNativeDateTimeModule,

		COMPONENTS,
		DIRECTIVES,
		PIPES
	],
	entryComponents: [
		COMPONENTS
	],
})
export class SharedModule {
	constructor(library: FaIconLibrary) {
		library.addIcons(...ICONS);
	}
}
