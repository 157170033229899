import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-chart',
	templateUrl: 'chart.component.html',
	styleUrls: ['chart.component.scss']
})
export class ChartComponent {
	@Input()
	data: ChartData[];

	get min(): number {
		return this.data.reduce((min, entry) => min < entry.value ? min : entry.value, 0);
	}

	get max(): number {
		return this.data.reduce((max, entry) => max > entry.value ? max : entry.value, 0);
	}

	get total(): number {
		return this.data.reduce((total, entry) => total + entry.value, 0);
	}

	getPercent(entry: ChartData): number {
		return parseFloat((entry.value * 100 / this.total).toFixed(3));
	}

	getStyles(entry: ChartData, subtitle: boolean = false) {
		return {
			'background-color': subtitle ? 'white' : (entry.color ?? 'transparent'),
			'width': `${this.getPercent(entry)}%`,
			'color': subtitle ? 'black' : 'white'
		};
	}
}
