import { Injectable, ErrorHandler, Injector, NgZone } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';

import { ToastrService, IndividualConfig } from 'ngx-toastr';

import { environment } from '@myapp/environment';

import * as StackTrace from 'stacktrace-js';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
	public constructor(
		private injector: Injector,
		private ngZone: NgZone,
		private router: Router,
		private httpClient: HttpClient
	) {
	}

	public async handleError(error: any) {
		let message: string = error.toString();

		let stack: string;
		try {
			// note: some errors cannot be parsed by stacktracejs (httperrorresponse etc)
			const stackTrace = await StackTrace.fromError(error);
			stack = JSON.stringify(stackTrace);
		} catch (traceError) {
			// just stringify the error
			stack = JSON.stringify(error);
		}

		// TODO: FINALIZE USERAGENT DATA
		const userAgent = {
			language: navigator.language,
			platform: navigator.platform,
			userAgent: navigator.userAgent			
		};
		// @ts-ignore
		if (navigator.connection) {
			// @ts-ignore
			userAgent.connectionDownlink = navigator.connection.downlink,	// connection speed
			// @ts-ignore
			userAgent.connectionEffectiveType = navigator.connection.effectiveType	// connection type '3g' etc
		}

		switch (error.constructor) {
			case ErrorEvent:
				this.showToast('toast-error', 'Error', `: ${error.message}`);
				break;

			case HttpErrorResponse:
				if (error.error && error.error.errors) {
					message = error.error.errors
						.map(e => e.code ? `${e.code}: ${e.description}` : e.description)
						.join('<br />');
				} else {
					message = error.message;
				}

				this.showToast('toast-error', 'Unexpected Error Occurred', `Oops... An unexpected error occurred. ${environment.errorMessages.errorSuffix}`);

				console.error(error.statusText, message);
				break;

			default:
				console.error(error);
				break;
		}

		// try logging the error
		const errorLog = {
			errorDate: new Date(),
			category: 'web',
			location: this.router.url,
			// TODO: GET USER/TEAMID USING A SHARED SERVICE INSTEAD OF DIRECT LOCALSTORAGE ACCESS
			userID: localStorage.getItem('userID'),
			teamID: localStorage.getItem('lastSelectedTeamID'),
			params: 'TODO:',		// TODO: GET ADDTIONAL PARAMS
			message: message ? message.slice(0, 995) : '',
			details: message + '\n====\n' + JSON.stringify(userAgent) + '\n====\n' + stack
		}
		this.httpClient.post(
			environment.rest.apiHost + '/v1/logging/error',
			errorLog
		).subscribe(
			() => console.log('error logged'),
			err => console.warn('failed to log error', err));

		// TODO: HANDLE ERROR LOGGING ISSUES BY CACHING AND LOGGING LATER
	}

	private showToast(
		type: 'toast-error' | 'toast-warning' | 'toast-success' | 'toast-info',
		title: string,
		message: string,
		options?: Partial<IndividualConfig>,
		action?: () => any
	) {
		this.ngZone.run(() => {
			let toastr = this.injector.get(ToastrService);
			let route = this.injector.get(ActivatedRoute);

			let childPath = undefined;
			if (route.snapshot.firstChild && route.snapshot.firstChild.url[0]) {
				childPath = route.snapshot.firstChild.url[0].path.toLowerCase();
			}

			if (childPath == 'portal') {
				toastr.show(message, title, options, type).onTap.subscribe(() => {
					if (action) {
						action();
					}
				});
			}
		});
	}
}

export const ErrorHandlerProvider = {
	provide: ErrorHandler,
	useClass: GlobalErrorHandler,
	multi: false
};
