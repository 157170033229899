import { Component } from '@angular/core';

import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
	templateUrl: 'whats-new.component.html'
})
export class WhatsNewComponent {
	featureName: string;

	constructor(
		private modalRef: BsModalRef
	) { }

	onDismiss() {
		this.modalRef.hide();
	}
}
