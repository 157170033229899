import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { trace } from '@angular/fire/performance';

import { Country, State, UserGeoInfo } from '@myapp/shared/models';

import { environment } from '@myapp/environment';

@Injectable({ providedIn: 'root' })
export class LookupService {
	private urlPrefix: string = 'lookup';

	constructor(
		private http: HttpClient
	) {
	}

	private getUrl(path: string, version: string = 'v1'): string {
		return `${environment.rest.apiHost}/${version}/${this.urlPrefix}/${path.replace('/', '')}`;
	}

	countries(): Observable<ResourceResponse<Country[]>> {
		return this.http
			.get<ResourceResponse<Country[]>>(this.getUrl('/countries', 'v1'))
			.pipe(trace('get-countries'));
	}

	states(): Observable<ResourceResponse<State[]>> {
		return this.http
			.get<ResourceResponse<State[]>>(this.getUrl('/states', 'v1'))
			.pipe(trace('get-states'));
	}

	geoByIP(): Observable<ResourceResponse<UserGeoInfo>> {
		return this.http
			.get<ResourceResponse<UserGeoInfo>>(this.getUrl('/geo', 'v1'))
			.pipe(trace('get-user-geo'));
	}
}
