import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
	templateUrl: 'prompt.component.html'
})
export class PromptComponent implements OnInit {
	model: ModalOptions;

	heading: SafeHtml;
	message: SafeHtml;

	input: string = null;
	errors: { [key: string]: any } = null;

	constructor(
		private modalRef: BsModalRef,
		private sanitizer: DomSanitizer,
		private cdr: ChangeDetectorRef
	) { }

	ngOnInit() {
		if (this.model.heading) {
			this.heading = this.sanitizer.bypassSecurityTrustHtml(this.model.heading);
		}

		if (this.model.message) {
			this.message = this.sanitizer.bypassSecurityTrustHtml(this.model.message);
		}

		this.model.submit = Object.assign({
			text: 'Submit',
			type: 'primary'
		}, this.model.submit);

		this.model.cancel = Object.assign({
			text: 'Cancel',
			type: 'link'
		}, this.model.cancel);

		this.model.input = Object.assign({
			required: true
		}, this.model.input);

		if (this.model.input && this.model.input.value) {
			this.input = this.model.input.value;
			this.onValidate();
		}
	}

	onSubmit() {
		this.modalRef.hide();
	}

	onCancel() {
		this.input = null;
		this.modalRef.hide();
	}

	onValidate() {
		if (this.model.input && this.model.input.validate) {
			this.errors = this.model.input.validate(this.input);
			this.cdr.detectChanges();
		}
	}
}
