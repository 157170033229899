<div class="modal-header" *ngIf="heading">
	<h5 class="modal-title" [innerHTML]="heading"></h5>

	<button type="button" class="close" (click)="onCancel()" aria-label="Close">
		<span aria-hidden="true">&times;</span>
	</button>
</div>

<div class="modal-body">
	<div class="form-group" [innerHTML]="message"></div>
</div>

<div class="modal-footer justify-content-center">
	<button type="button" class="btn" [ngClass]="'btn-' + model.cancel.type" (click)="onCancel()">
		{{ model.cancel.text }}
	</button>

	<button type="submit" class="btn rounded rounded-pill" [ngClass]="'btn-' + model.submit.type" (click)="onSubmit()">
		{{ model.submit.text }}
	</button>
</div>
