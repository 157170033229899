<div class="modal-header">
	<h5 id="modalWhatsNewHeader" class="modal-title">What's New?</h5>
	<button class="btn-close close pull-right" type="button" data-bs-dismiss="modal" aria-label="Close"
		(click)="onDismiss()">&times;</button>
</div>

<div class="modal-body text-center p-4 p-sm-5">
	<div class="mb-4"><fa-icon class="text-primary" [icon]="['fas', 'sticky-note']" size="5x"></fa-icon></div>

	<h4 class="mb-4">
		<span class="handwritten-underline-primary">Time Log Notes</span> Released!
	</h4>

	<p>
		You can now add a notes to any time log and view them under My Totals, Team Totals and when you export your logs as a CSV file (will be part of Time Approvals soon).
	</p>
</div>

<div class="modal-footer">
	<button id="modalWhatsNewDismiss" type="button" class="btn btn-primary px-5 mx-auto" (click)="onDismiss()">
		OK
	</button>
</div>



<!-- APPROVALS FEATURE -->
<!-- <div class="modal-header">
	<h5 id="modalWhatsNewHeader" class="modal-title">What's New?</h5>
	<button class="btn-close close pull-right" type="button" data-bs-dismiss="modal" aria-label="Close"
		(click)="onDismiss()">&times;</button>
</div>

<div class="modal-body text-center p-4 p-sm-5">
	<div class="mb-4"><fa-icon class="text-primary" [icon]="['fas', 'calendar-check']" size="5x"></fa-icon></div>

	<h4 class="mb-4">
		<span class="handwritten-underline-primary">Time Approvals</span> Released!
	</h4>

	<p>
		<strong>Time Approvals</strong> (Approvals) allows you to require your employees to submit their time weekly, and allows you to review & approve their time.
	</p>

	<p>
		Approvals is enabled for your team by default. You can disable it under your <a routerLink="/settings" (click)="onDismiss()">Team Settings</a>.
	</p>
</div>

<div class="modal-footer">
	<button id="modalWhatsNewDismiss" type="button" class="btn btn-primary px-5 mx-auto" (click)="onDismiss()">
		OK
	</button>
</div> -->
