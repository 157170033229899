<div class="progress">
	<ng-container *ngFor="let entry of data">
		<div class="progress-bar" [style]="getStyles(entry, false)" [tooltip]="entry.detail" role="progressbar"
			[attr.aria-valuenow]="entry.value" [attr.aria-valuemin]="min" [attr.aria-valuemax]="max">
			<div>{{ getPercent(entry) > 5 ? entry.label : '' }}</div>
		</div>
	</ng-container>
</div>
<!-- <div class="progress">
	<ng-container *ngFor="let entry of data">
		<div class="progress-bar" [style]="getStyles(entry, true)" [tooltip]="entry.detail" role="progressbar"
			[attr.aria-valuenow]="entry.value" [attr.aria-valuemin]="min" [attr.aria-valuemax]="max">
			<div>{{ getPercent(entry) > 10 ? entry.name : '' }}</div>
		</div>
	</ng-container>
</div> -->
