import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-spinner-fullscreen',
	templateUrl: 'spinner-fullscreen.component.html',
	styleUrls: ['spinner-fullscreen.component.scss']
})
export class SpinnerFullscreenComponent {
	@Input()
	message: string;
}
