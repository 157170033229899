import {
	faAngleDown as fasAngleDown,
	faAngleUp as fasAngleUp,
	faAngleRight as fasAngleRight,
	faCheck as fasCheck,
	faCreditCard as fasCreditCard,
	faSlidersHSquare as fasSlidersHSquare,
	faBoxFull as fasBoxFull,
	faSpinnerThird as fasSpinnerThird,
	faCloudDownload as fasCloudDownload,
	faPlus as fasPlus,
	faTimes as fasTimes,
	faPencil as fasPencil,
	faStopwatch as fasStopwatch,
	faUser as fasUser,
	faUsers as fasUsers,
	faInfoCircle as fasInfoCircle,
	faChevronLeft as fasChevronLeft,
	faChevronRight as fasChevronRight,
	faCalendarCheck as fasCalendarCheck,
	faFileInvoiceDollar as fasFileInvoiceDollar,
	faLockAlt as fasLockAlt,
	faUsdCircle as fasUsdCircle,
	faCheckCircle as fasCheckCircle,
	faEnvelope as fasEnvelope,
	faCog as fasCog,
	faStickyNote as fasStickyNote,
} from '@fortawesome/pro-solid-svg-icons';

import {
	faCheck as falCheck,
	faChevronRight as falChevronRight,
	faClock as falClock,
	faUserFriends as falUserFriends,
	faSlidersH as falSlidersH,
	faMoneyCheckAlt as falMoneyCheckAlt,
	faAngleDown as falAngleDown,
	faAngleUp as falAngleUp,
	faSearch as falSearch,
	faMoneyCheckEditAlt as falMoneyCheckEditAlt
} from '@fortawesome/pro-light-svg-icons';

import {
	faBars as farBars,
	faTimes as farTimes,
	faPlay as farPlay,
	faPlayCircle as farPlayCircle,
	faStopCircle as farStopCircle,
	faSpinnerThird as farSpinnerThird,
	faArrowDown as farArrowDown,
	faCalendarStar as farCalendarStar,
	faCheck as farCheck,
	faMoneyCheckEditAlt as farMoneyCheckEditAlt,
	faStickyNote as farStickyNote
} from '@fortawesome/pro-regular-svg-icons';

import {
	faSpinnerThird as fadSpinnerThird,
} from '@fortawesome/pro-duotone-svg-icons';

import {
	faCcAmex as fabCcAmex,
	faCcDinersClub as fabCcDinersClub,
	faCcDiscover as fabCcDiscover,
	faCcJcb as fabCcJcb,
	faCcMastercard as fabCcMastercard,
	faCcVisa as fabCcVisa,
} from '@fortawesome/free-brands-svg-icons';

export const ICONS = [
	fasAngleDown,
	fasAngleUp,
	fasAngleRight,
	fasCheck,
	fasCreditCard,
	fasSlidersHSquare,
	fasBoxFull,
	fasSpinnerThird,
	fasCloudDownload,
	fasPlus,
	fasTimes,
	fasPencil,
	fasStopwatch,
	fasUser,
	fasUsers,
	fasInfoCircle,
	fasChevronLeft,
	fasChevronRight,
	fasCalendarCheck,
	fasFileInvoiceDollar,
	fasLockAlt,
	fasUsdCircle,
	fasCheckCircle,
	fasEnvelope,
	fasCog,
	fasStickyNote,

	falCheck,
	falChevronRight,
	falClock,
	falUserFriends,
	falSlidersH,
	falMoneyCheckAlt,
	falAngleDown,
	falAngleUp,
	falSearch,
	falMoneyCheckEditAlt,

	farBars,
	farTimes,
	farPlay,
	farPlayCircle,
	farStopCircle,
	farSpinnerThird,
	farArrowDown,
	farCalendarStar,
	farCheck,
	farMoneyCheckEditAlt,
	farStickyNote,

	fadSpinnerThird,

	fabCcAmex,
	fabCcDinersClub,
	fabCcDiscover,
	fabCcJcb,
	fabCcMastercard,
	fabCcVisa,
];
