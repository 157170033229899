import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [{
	path: 'account',
	loadChildren: () => import('@myapp/account/module').then(m => m.AccountModule)
}, {
	path: '',
	loadChildren: () => import('@myapp/portal/module').then(m => m.PortalModule)
}, {
	path: '**',
	redirectTo: ''
}];

@NgModule({
	imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
	exports: [RouterModule]
})
export class AppRoutingModule { }
